import React from "react"
import styled from "styled-components"
import Grid from "../zzz/layout/grid"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"

const DeliveryWrapper = styled.div`
  margin: 40px 0;
  strong {
    font-weight: 500;
  }
  h1 {
    margin-bottom: 20px;
  }
  h5 {
    font-weight: 500;
  }
  h5,
  p {
    padding-bottom: 1rem;
  }
`
const PageContent = styled.div`
  background: #ffffff;
  h3 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &:before {
      display: block;
      content: "";
      background: #ed1c24;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 15px;
    }
  }
  a {
    color: #0000ee;
  }
`

const DeliveryPage = ({ location }) => {
  const page = useStaticQuery(graphql`
    {
      page: localWpGraphQlPages(slug: { eq: "delivery" }) {
        seo {
          yoastTitle
          yoastDescription
          yoastMeta
          yoastSchema
          woocommerceSchema
        }
        title
        content
        pageId
      }
    }
  `)

  const seo = page.page.seo

  return (
    <Layout location={location}>
      <SEO
        title={page.page.title}
        location={location}
        post_id={page.page.pageId}
        seo={seo}
      />
      <DeliveryWrapper>
        <Grid>
          <h1 dangerouslySetInnerHTML={{ __html: page.page.title }} />
          <PageContent
            dangerouslySetInnerHTML={{ __html: page.page.content }}
          />
        </Grid>
      </DeliveryWrapper>
    </Layout>
  )
}

export default DeliveryPage
